import map from 'lodash/map'
import sortBy from 'lodash/sortBy'

import { findObject } from '@/services/Helpers'
import CMSArticle from '@/database/models/CMSArticle'
import CMSCareerArticle from '@/database/models/CMSCareerArticle'
import CMSMetaArticle from '@/database/models/CMSMetaArticle'
import CMSNewsArticle from '@/database/models/CMSNewsArticle'
import CMSTag from '@/database/models/CMSTag'
import CMSTitle from '@/database/models/CMSTitle'

import LogService from '@/services/LogService'
import { getCmsArticle, getCmsArticles, getTags, getTitles } from '~/services/network/utils/cms.convenience.axios'
import { getMetaMockData } from '~/services/network/utils/helper.convenience.axios'

export const state = () => ({
  tags: [],
  titles: [],
  news: [],
  meta: [],
  career: []
})

export const mutations = {
  'SET_TAGS' (state, { tags }) {
    state.tags = tags
  },
  'SET_TITLES' (state, { titles }) {
    state.titles = titles
  },
  'SET_NEWS' (state, { news }) {
    state.news = news
  },
  'SET_META' (state, { posts }) {
    state.meta = posts
  },
  'SET_CAREER' (state, { posts }) {
    state.career = posts
  }
}

export const actions = {
  async getCareerPosts ({ commit }, { query = {}, cancelToken = null }) {
    /** @type {FetchResult} */
    const result = await getCmsArticles({
      query: {
        article_type: CMSArticle.ArticleType.careerPost,
        ...query
      }
    })

    commit('SET_CAREER', { posts: result.data })
  },

  async getBlogPosts ({ commit, getters, rootGetters }, { query = {}, cancelToken = null, worker = true, persist = true }) {
    try {
      const result = await getMetaMockData({
        id: 'articles',
        query: {
          type: 'BLOG',
          limit: 5,
        }
      })
      const posts = result.data
      if(persist) {
        commit('SET_NEWS', {
          news: posts
        })
      }
      return posts
    } catch (e) {
      LogService.error(e)
      throw e
    }
  },

  async getMetaPosts ({ commit, rootGetters }, { query = {}, cancelToken = null }) {
    let meta = []

    try {
      const result = await getCmsArticles({
        query: {
          article_type: CMSArticle.ArticleType.eshopMeta,
          ...query
        }
      })
      meta = result.data

      commit('SET_META', { posts: meta })
    } catch (e) {
      LogService.error(e)
      throw e
    }
  },

  async getBlogPost ({ commit, getters }, { id = null, slug = null }) {
    let result = null

    /** @type {Object} */
    result = await getCmsArticle({ id: id || slug })

    return new CMSNewsArticle(result)
  },

  async getTags ({ commit, rootGetters }) {
    let tags = []

    try {
      const result = await getTags({})
      tags = result.data
    } catch (e) {
      LogService.error(e)
    }

    commit('SET_TAGS', { tags })
  },
  async getTitles ({ commit, rootGetters }) {
    let titles = []

    try {
      const result = await getTitles({})
      titles = result.data
    } catch (e) {
      LogService.error(e)
    }

    commit('SET_TITLES', { titles })
  }
}

export const getters = {
  blogPosts: (state) => {
    return map(state.news, news => new CMSNewsArticle(news))
  },
  shortBlogPosts: (state, getters) => {
    const news = sortBy(getters.blogPosts, 'created')
    news.reverse()
    return news
  },
  blogPostForId: (state, getters) => (id) => {
    return findObject(getters.blogPosts, post => {
      return post.id === id
    })
  },
  blogPostForSlug: (state, getters) => (slug) => {
    return findObject(getters.blogPosts, post => {
      return post.slug === slug
    })
  },
  careerPosts: (state) => {
    return map(state.career, post => new CMSCareerArticle(post))
  },
  metaPosts: (state) => {
    return map(state.meta, post => new CMSMetaArticle(post))
  },
  metaPostForSlug: (state, getters) => (slug) => {
    return findObject(getters.metaPosts, post => {
      return post.slug === slug
    })
  },

  tags: (state) => {
    return map(state.tags, tag => new CMSTag(tag))
  },
  titles: (state) => {
    return map(state.titles, title => new CMSTitle(title))
  }
}
