import filter from 'lodash/filter'
import map from 'lodash/map'
import sumBy from 'lodash/sumBy'

import SellCart from '@/database/models/SellCart'
import SellCartItem from '@/database/models/SellCartItem'

import LogService from '@/services/LogService'
import { runTask } from '~/services/network/utils/axios'

import {
  applyCartVoucher,
  deleteCartItem,
  emptyCart,
  getCart,
  insertCartItem, insertCartItems, insertCartItemBulk, unapplyCartVoucher,
  updateCartItem
} from '~/workers/network.worker'
import { decodeLoc } from '~/structures/Localizable'

export const state = () => ({
  cart: {...(new SellCart({}))},
  isOperating: false,
  isEmptying: false,
})

export const mutations = {
  'SET_CART' (state, value) {
    state.cart = value
  },
  'SET_OPERATING' (state, value) {
    state.isOperating = value
  },
  'SET_EMPTYING' (state, value) {
    state.isEmptying = value
  }
}

export const actions = {
  async init ({ commit, dispatch }) {
    await dispatch('refreshCart', {slRefresh: false})
  },

  async reloadCart ({ dispatch }) {
    dispatch('eraseCart')
    await dispatch('refreshCart')
  },

  async refreshCart ({ commit, dispatch, rootGetters }, {slRefresh = true} = {slRefresh: true}) {
    try {
      commit('SET_OPERATING', true)

      const cartData = await runTask(getCart())

      if(slRefresh && rootGetters['authentication/isAuthenticated']) {
        dispatch('shoppingLists/getShoppingLists', null, {root: true})
      }

      commit('SET_CART', cartData)
    } catch (e) {
      LogService.error(e)
    } finally {
      commit('SET_OPERATING', false)
    }
  },

  async insertItemToCart ({ commit, dispatch }, { article, count, refresh = true }) {
    const articleId = article.id

    try {
      commit('SET_OPERATING', true)
      dispatch('shoppingLists/cancelGetter', null, {root: true})

      const result = await runTask(insertCartItem({
        data: {
          article: articleId,
          count: count || 1
        }
      }))
      if(refresh) {
        await dispatch('refreshCart')
      }

      // If user has some unvisited automatic shopping lists and inserts
      // new item into cart, set visited all automatic lists for no more
      // of shopping list alert
      setTimeout(function () {
        dispatch('shoppingLists/setShoppingListsAsVisited', null, {root: true})
      }, 100)

      return result
    } finally {
      commit('SET_OPERATING', false)
    }
  },

  async insertItemsToCart({commit, dispatch, rootGetters}, {articles = []}) {
    try {
      commit('SET_OPERATING', true)
      dispatch('shoppingLists/cancelGetter', null, {root: true})
      const result = await runTask(insertCartItems({
        data: articles
      }))
      await dispatch('refreshCart')

      // If user has some unvisited automatic shopping lists and inserts
      // new item into cart, set visited all automatic lists for no more
      // of shopping list alert
      setTimeout(function () {
        if(rootGetters['authentication/isAuthenticated']) {
          dispatch('shoppingLists/setShoppingListsAsVisited', null, {root: true})

        }
      }, 10)

      return result
    } finally {
      commit('SET_OPERATING', false)
    }
  },

  async insertItemToCartBulk ({ commit, dispatch }, { articles = [] }) {
    try {
      commit('SET_OPERATING', true)
      const result = await runTask(insertCartItemBulk({
        data: map(articles, article => {
          return {
            article: article.id,
            count: article.count || 1
          }
        })
      }))

      return result
    } finally {
      commit('SET_OPERATING', false)
    }
  },


  async updateCartItem ({ commit, dispatch }, { article, cartItemId, count = 1 }) {
    const articleId = article.id

    try {
      commit('SET_OPERATING', true)
      dispatch('shoppingLists/cancelGetter', null, {root: true})
      await runTask(updateCartItem({
        id: cartItemId,
        data: {
          article: articleId,
          count
        }
      }))
      await dispatch('refreshCart')
    } finally {
      commit('SET_OPERATING', false)
    }
  },

  async removeItemFromCart ({ dispatch, commit, getters }, cartItemId) {
    try {
      dispatch('shoppingLists/cancelGetter', null, {root: true})
      const cart = {...getters.cart}
      cart.items = filter(cart.items, item => item.id !== cartItemId)
      commit('SET_CART', cart)

      await runTask(deleteCartItem({id: cartItemId}))
    } catch (e) {
      LogService.warn(e)
    } finally {
      dispatch('refreshCart')
    }
  },

  async emptyCart ({ commit, dispatch }) {
    try {
      commit('SET_EMPTYING', true)
      commit('SET_OPERATING', true)
      dispatch('shoppingLists/cancelGetter', null, {root: true})
      await runTask(emptyCart())
    } catch (e) {
      LogService.error(e)
    } finally {
      commit('SET_EMPTYING', false)
      dispatch('refreshCart')
    }
  },

  eraseCart ({commit}) {
    commit('SET_CART', {...SellCart.fields()})
  },

  async setVoucher ({ dispatch }, { code }) {
    dispatch('shoppingLists/cancelGetter', null, {root: true})
    await runTask(applyCartVoucher({
      data: {
        code
      }
    }))
    await dispatch('authentication/loadProfileDetail', {}, { root: true })
    await dispatch('refreshCart')
  },
  async removeVoucher ({ dispatch }) {
    dispatch('shoppingLists/cancelGetter', null, {root: true})
    await runTask(unapplyCartVoucher())
    await dispatch('refreshCart')
  }
}

export const getters = {
  getLocCurrency: (state, getters, rootState, rootGetters) => (loc) => decodeLoc(loc, rootGetters['general/getCurrencyLower']),

  cart: (state) => {
    return new SellCart(state.cart || {})
  },
  isInitialized: (state, getters) => {
    return getters.cart instanceof SellCart
  },
  isOperating: (state) => {
    return state.isOperating
  },
  isEmptying: (state) => {
    return state.isEmptying
  },
  currency: (state, getters, rootState, rootGetters) => {
    return rootGetters['general/getCurrencyLower']
  },

  // Loyalty progress
  hasNextLoyaltyLevelPotential: (state, getters) => {
    if (!getters.isInitialized) return false

    return getters.cart.potentialNextLoyaltyLevelObject !== null
  },
  potentialNextLoyaltyLevel: (state, getters) => {
    return getters.hasNextLoyaltyLevelPotential ? getters.cart.potentialNextLoyaltyLevelObject.level || null : null
  },
  potentialNextLoyaltyProgressPercentage: (state, getters) => {
    if (!getters.hasNextLoyaltyLevelPotential) return 0
    return Math.floor((getters.cart.potentialNextLoyaltyLevelObject.progress || 0) * 100)
  },

  // Items
  cartItems: (state, getters) => {
    return map(getters.cart.items, item => new SellCartItem(item))
  },
  getItemCount: (state, getters) => sku => {
    const cartItems = getters.cartItems
    const contextItem = cartItems.find(item => item.sku === sku)
    let count = 0

    if(contextItem) {
      count = contextItem.count
    }

    return count
  },
  showBulkBanner: (state, getters) => {
    const singles = filter(getters.cartItems, item => !item.isNonSingle)
    return singles.length > 4
  },
  cartItemsCount: (state, getters) => {
    return getters.cartItems.length || 0
  },
  hasCartItems: (state, getters) => {
    return getters.cartItemsCount > 0
  },
  voucher: (state, getters) => {
    return getters.isInitialized ? getters.cart.voucher : null
  },
  promoVoucher: (state, getters) => {
    return getters.isInitialized ? getters.cart.promoVoucher : null
  },
  hasAnyVoucherApplied: (state, getters) => {
    return getters.isInitialized && getters.cart.hasVoucherApplied
  },
  estimatedDispatchDate: (state, getters) => {
    return getters.isInitialized ? getters.cart.estimatedDispatchDate : ''
  },
  estimatedPickupDate: (state, getters) => {
    return getters.isInitialized ? getters.cart.estimatedPickupDate : ''
  },

  loyaltyDiscountAppliedTo: (state, getters) => {
    const currency = getters.currency
    const loyaltyDiscounted = filter(getters.cartItems, (item) => {
      return decodeLoc(item.discountTypeLoc, currency) === 'LOYALTY'
    })

    return loyaltyDiscounted.length
  },
  voucherDiscountAppliedTo: (state, getters) => {
    const currency = getters.currency
    const loyaltyDiscounted = filter(getters.cartItems, (item) => {
      return decodeLoc(item.discountTypeLoc, currency) === 'VOUCHER'
    })

    return loyaltyDiscounted.length
  },
  hasMultipleDiscountTypes: (state, getters) => {
    return getters.isInitialized ? getters.cart.hasMultipleDiscountTypes : false
  },
  voucherDiscountTotal: (state, getters) => {
    return getters.isInitialized ? getters.getLocCurrency(getters.cart.voucherDiscountLoc) : 0
  },
  voucherLostValue: (state, getters) => {
    const userTotalPrice = getters.totalUserPrice

    return Math.abs(Math.min(0, userTotalPrice))
  },
  isVoucherAbsolute: (state, getters) => {
    return getters.isInitialized && (getters.cart.isVoucherAbsolute || getters.cart.isPromoVoucherAbsolute)
  },
  loyaltyDiscountTotal: (state, getters) => {
    return getters.isInitialized ? getters.getLocCurrency(getters.cart.loyaltyDiscountLoc) : 0
  },

  buyItemsCount: (state, getters) => {
    if (!getters.isInitialized) return 0
    return sumBy(getters.cartItems, 'count')
  },

  basePrice: (state, getters) => {
    return getters.isInitialized ? getters.getLocCurrency(getters.cart.totalPriceLoc) : 0
  },
  basePriceDisplay: (state, getters) => {
    return Math.max(getters.basePrice, 0)
  },
  userPriceCzk: (state, getters) => {
    return getters.isInitialized ? getters.cart.subtotalCzk : 0
  },
  userPrice: (state, getters) => {
    return getters.isInitialized ? getters.getLocCurrency(getters.cart.userTotalPriceLoc) : 0
  },
  userPriceDisplay: (state, getters) => {
    return Math.max(getters.userPrice, 0)
  },
  userPriceNotRounded: (state, getters) => {
    return getters.isInitialized ? getters.getLocCurrency(getters.cart.userTotalPriceNotRoundedLoc) : 0
  },
  userPriceNotRoundedDisplay: (state, getters) => {
    return Math.max( getters.userPriceNotRounded, 0)
  },

  userPriceWShipping: (state, getters, rootState, rootGetters) => {
    const userPrice = getters.userPrice
    const shippingPrice = rootGetters['checkout/shippingMethodPrice']

    return userPrice + shippingPrice
  },
  userPriceWShippingPayment: (state, getters, rootState, rootGetters) => {
    const priceWShipping = getters.userPriceWShipping
    const paymentPrice = rootGetters['checkout/paymentMethodPrice']

    return priceWShipping + paymentPrice
  },

  totalUserPrice: (state, getters, rootState, rootGetters) => {
    const userPriceWShippingPayment = getters.userPriceWShippingPayment

    const useCreditPayment = rootGetters['checkout/useCreditForPayment']
    const userCredit = rootGetters['authentication/userTotalCredit']

    let total = userPriceWShippingPayment

    if (useCreditPayment) {
      total -= userCredit
    }

    return total
  },
  totalUserPriceDisplay: (state, getters) => {
    return Math.max(getters.totalUserPrice, 0)
  },

  buyItemsTotal: (state, getters) => {
    return getters.isInitialized ? getters.getLocCurrency(getters.cart.totalPriceLoc) - getters.voucherDiscountTotal : 0
  },
  buyItemsUserTotal: (state, getters) => {
    return getters.isInitialized ? getters.getLocCurrency(getters.cart.userTotalPriceLoc) : 0
  },
  buyItemsTotalFinalNoCredit: (state, getters, rootState, rootGetters) => {
    const itemsTotalPrice = getters.buyItemsTotal
    const paymentMethodPrice = rootGetters['checkout/paymentMethodPrice']
    const shippingMethodPrice = rootGetters['checkout/shippingMethodPrice']

    return itemsTotalPrice + paymentMethodPrice + shippingMethodPrice
  },
  buyItemsUserTotalFinal: (state, getters, rootState, rootGetters) => {
    const totalNoCredit = getters.buyItemsTotalFinalNoCredit
    const useCreditPayment = rootGetters['checkout/useCreditForPayment']
    const userCredit = rootGetters['authentication/userTotalCredit']

    let total = totalNoCredit

    if (useCreditPayment) {
      total -= userCredit

      if (total < 0) total = 0
    }

    return total
  },
  buyItemsTotalWithCurrency: (state, getters, rootState, rootGetters) => {
    return `${getters.buyItemsUserTotal} ${rootGetters['general/getCurrency']}`
  }
}
