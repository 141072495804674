import capitalize from 'lodash/capitalize'
import each from 'lodash/each'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import Hello from '@/database/models/Hello'
import NavigationPage from '@/database/models/NavigationPage'
import StatusMessage from '~/database/models/StatusMessage'
import { setDefaultLocale } from '~/services/DateService'
import LogService from '~/services/LogService'
import { flattenNavigation } from '~/services/NavigationService'
import { parseArray, runTask } from '~/services/network/utils/axios'
import {
  getNavigationLinks,
  getNavigationPageFilter,
  getNavigationPageBestSellers, getMetaMockData
} from '~/services/network/utils/helper.convenience.axios'
import { getNavigationPageBestSellers as getNavigationPageBestSellersWorker, createRedirectRecord, hello, updateHello } from '~/workers/network.worker'
import Article from '~/database/models/Article'

export const state = () => ({
  settings: {
    language: 'cs',
    currency: 'CZK',
    country: 'CZ'
  },
  cookies_consent: false,
  status: null,
  navigation: {
    flat: []
  }
})

export const mutations = {
  'SETTINGS_SET' (state, payload) {
    if ('language' in payload) {
      const language = payload.language.split('-')[0] || payload.language
      setDefaultLocale(language)
    }

    each(payload, function (item, key) {
      if (key in state.settings) {
        if (key === 'language') {
          state.settings[key] = item.split('-')[0] || item
          return
        }
        state.settings[key] = item
      }
    })
  },
  'NAVIGATION_SET' (state, payload) {
    state.navigation = Object.freeze(payload)
  },
  'COOKIES_CONSENT_SET' (state, payload) {
    state.cookies_consent = payload
  },
  'STATUS_SET' (state, status) {
    state.status = status
  }
}

export const actions = {
  async hello ({ commit, dispatch }) {
    try {
      const result = await runTask(hello())
      const helloObject = new Hello(result)
      commit('SETTINGS_SET', helloObject)

      this.$i18n.setLocale(helloObject.language)
    } catch (e) {
      LogService.error(e)
    }
  },

  async updateHello ({ commit }, data) {
    const result = await runTask(updateHello({data}))
    const hello = new Hello(result)
    commit('SETTINGS_SET', hello)

    this.$i18n.setLocale(hello.language)
  },

  createRedirectRecord (_, {url}) {
    return runTask(createRedirectRecord({
      data: {
        old_url: url
      }
    }))
  },

  async fetchStatusMessage ({ commit }) {
    try {
      const response = await getMetaMockData({id: 'status'})
      commit('STATUS_SET', {...response.data?.[0]?.status})
    } catch (e) {
      LogService.error(e)
    }
  },

  getPageFilters(_, {page}) {
    return getNavigationPageFilter({
      query: {
        navigation_page: page
      }
    })
  },

  fetchBestsellersForPage ({ commit, dispatch, rootGetters }, {id, query, worker = true, cancelId}) {
    const queryBase = {
      offset: 0,
      limit: 3,
      ...query
    }
    let promise = null

    if(worker) {
      promise = runTask(getNavigationPageBestSellersWorker({id, query: queryBase, cancelId}))
    } else {
      promise = getNavigationPageBestSellers({id, query: queryBase, cancelSignal: null})
    }

    return parseArray(Article, promise)
  },

  async fetchNavigationRules ({ commit, dispatch, rootGetters }, data = {}) {
    let navigationLinks = data

    if(isEmpty(navigationLinks)) {
      // Should fetch new data
      navigationLinks = await getNavigationLinks()
    }

    const flatMap = flattenNavigation(navigationLinks, true)

    navigationLinks = map(flatMap, item => {
      const page = {
        ...item
      }

      if (NavigationPage.isCategoryOverview(page)) {
        page.category = NavigationPage.getCategoryId(page)
      }

      return page
    })

    commit('NAVIGATION_SET', {
      flat: navigationLinks
    })
  },

  setCurrency ({ commit }, currency) {
    commit('SETTINGS_SET', { currency })
  },

  async refreshCarts ({ dispatch }) {
    await Promise.all([
      dispatch('cart/refreshCart', {}, { root: true }),
      dispatch('cartBuyup/refreshCart', {}, { root: true })
    ])
  },
  async agreeWithCookies ({ commit, dispatch, rootGetters }) {
    commit('COOKIES_CONSENT_SET', true)

    if (rootGetters['authentication/isAuthenticated']) {
      await dispatch('authentication/updateUser', {
        agree_to_cookies: true
      }, { root: true })
    }
  }
}

export const getters = {
  hasStatusMessage: (state, getters) => {
    return state.status !== null && !getters.statusMessage.isEmpty
  },
  statusMessage: state => {
    return new StatusMessage(state.status || {})
  },
  getCurrency: state => {
    return state.settings.currency
  },
  isCurrencyCzk: (state, getters) => {
    return getters.getCurrency === 'CZK'
  },
  getCurrencyLower: (state, getters) => {
    return getters.getCurrency.toLowerCase()
  },
  getCurrencyCamel: (state, getters) => {
    return capitalize(getters.getCurrencyLower)
  },
  getLanguage: state => {
    return state.settings.language
  },
  getCountry: state => {
    return state.settings.country
  },
  cookiesConsent: state => {
    return state.cookies_consent
  },
}
