import { setForwardedFor } from '~/services/network/utils/axios'

export const actions = {
  async nuxtServerInit({ dispatch, commit }, { req, app }) {
    const reqForwardedFor = req.headers['do-connecting-ip'] || req.headers['x-forwarded-for']

    if(reqForwardedFor) {
      setForwardedFor(reqForwardedFor)
    }

    await Promise.all([
      dispatch('menu/getMenu'),
      dispatch('configurations/fetchConfiguration', {
        type: 'global,singles'
      })
    ])
  }
}
