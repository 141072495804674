import map from 'lodash/map'
import BillingAddress from '~/database/models/BillingAddress'
import ShippingAddress from '~/database/models/ShippingAddress'
import { runTask } from '~/services/network/utils/axios'
import {
  createBillingAddress,
  createShippingAddress,
  getBillingAddresses,
  getShippingAddresses, updateBillingAddress,
  updateShippingAddress
} from '~/workers/network.worker'

export const state = () => ({
  shippingAddresses: [],
  billingAddresses: [],
})

export const mutations = {
  'SET_SHIPPING_ADDRESSES': (state, {addresses}) => {
    state.shippingAddresses = addresses
  },
  'SET_BILLING_ADDRESSES': (state, {addresses}) => {
    state.billingAddresses = addresses
  }
}

export const actions = {
  async getShippingAddresses ({ commit, rootGetters }) {
    /** @type FetchResult */
    const result = await runTask(getShippingAddresses({
      query: { user: rootGetters['authentication/user'].id }
    }))

    commit('SET_SHIPPING_ADDRESSES', {addresses: result.data})
  },
  async createShippingAddress ({ commit, dispatch, getters, rootGetters }, { address }) {
    address.user = rootGetters['authentication/user'].id
    const addressResult = await runTask(createShippingAddress({
      data: ShippingAddress.mapProperties(address)
    }))

    commit('SET_SHIPPING_ADDRESSES', {addresses: [...getters.shippingAddresses, addressResult]})
    return addressResult
  },
  async updateShippingAddress ({ commit, dispatch, getters }, { address }) {
    return await runTask(updateShippingAddress({
      id: address.id,
      data: ShippingAddress.mapProperties(address)
    }))
  },

  async getBillingAddresses ({ commit, rootGetters }) {
    /** @type FetchResult */
    const result = await runTask(getBillingAddresses({
      query: { user: rootGetters['authentication/user'].id }
    }))

    commit('SET_BILLING_ADDRESSES', {addresses: result.data})
  },
  async createBillingAddress ({ commit, dispatch, getters, rootGetters }, { address }) {
    address.user = rootGetters['authentication/user'].id
    const addressResult = await runTask(createBillingAddress({
      data: BillingAddress.mapProperties(address)
    }))

    commit('SET_BILLING_ADDRESSES', {addresses: [...getters.billingAddresses, addressResult]})
    return addressResult
  },
  async updateBillingAddress ({ commit, dispatch, getters }, { address }) {
    return await runTask(updateBillingAddress({
      id: address.id,
      data: BillingAddress.mapProperties(address)
    }))
  },

  clearAddresses({commit}) {
    commit('SET_SHIPPING_ADDRESSES', {addresses: []})
    commit('SET_BILLING_ADDRESSES', {addresses: []})
  }
}

export const getters = {
  shippingAddresses: (state) => {
    return map(state.shippingAddresses, address => new ShippingAddress(address))
  },
  billingAddresses: (state) => {
    return map(state.billingAddresses, address => new BillingAddress(address))
  }
}
