import filter from 'lodash/filter'
import last from 'lodash/last'
import map from 'lodash/map'
import sumBy from 'lodash/sumBy'

import BuyCart from '@/database/models/BuyCart'
import BuyCartItem from '@/database/models/BuyCartItem'
import LogService from '@/services/LogService'
import { runTask } from '~/services/network/utils/axios'

import {
  deleteBuylistCartItem, emptyBuylistCart,
  getBuylistCart,
  insertBuylistCartItem, insertBuylistCartItemBulk,
  splitBuylistCartItem,
  updateBuylistCartItem
} from '~/workers/network.worker'
import { decodeLoc } from '~/structures/Localizable'

export const state = () => ({
  cart: {...(new BuyCart({}))},
  cartLastSeen: null,
  isOperating: false,
  isEmptying: false
})

export const mutations = {
  'SET_CART' (state, cart) {
    state.cart = cart
  },
  'SET_OPERATING' (state, value) {
    state.isOperating = value
  },
  'SET_EMPTYING' (state, value) {
    state.isEmptying = value
  },
  'SET_CART_SEEN' (state, timestamp) {
    state.cartLastSeen = timestamp
  }
}

export const actions = {
  async init ({ commit, dispatch }) {
    await dispatch('refreshCart')
  },

  async refreshCart ({ commit }) {
    try {
      commit('SET_OPERATING', true)

      const cartData = await runTask(getBuylistCart())
      commit('SET_CART', cartData)
    } catch (e) {
      LogService.error(e)
    } finally {
      commit('SET_OPERATING', false)
    }
  },

  async insertOrUpdateItemToCart ({ dispatch, getters }, { offer, condition = 'NM', count = 1, language = 'en' }) {
    const offerId = offer.id
    const langUpper = language.toUpperCase()
    const lastCartItem = last(getters.cartItems)

    if (lastCartItem && offerId === lastCartItem.offer.id && lastCartItem.condition === condition && lastCartItem.languageCode === langUpper) {
      return await dispatch('updateCartItem', {
        offer,
        condition,
        language,
        cartItemId: lastCartItem.id,
        count: lastCartItem.count + count
      })
    } else {
      return await dispatch('insertItemToCart', {
        offer, condition, count, language
      })
    }
  },

  async insertItemToCart ({ dispatch, commit }, { offer, id = null, condition = 'NM', language = 'en', count = 1, noRefresh = false }) {
    const offerId = id || offer.id

    try {
      commit('SET_OPERATING', true)

      return await runTask(insertBuylistCartItem({
        data: {
          offer: offerId,
          count,
          condition,
          language
        }
      }))
    } catch (e) {
      LogService.error(e)
      throw e
    } finally {
      commit('SET_OPERATING', false)
      if (!noRefresh) {
        dispatch('refreshCart')
      }
    }
  },

  async insertItemToCartBulk ({ dispatch, commit }, { offers }) {
    try {
      commit('SET_OPERATING', true)

      return await runTask(insertBuylistCartItemBulk({
        data: map(offers, offer => {
          return {
            offer: offer.id,
            count: offer.count,
            condition: offer.condition,
            language: offer.language
          }
        })
      }))
    } catch (e) {
      LogService.error(e)
      throw e
    } finally {
      commit('SET_OPERATING', false)
      dispatch('refreshCart')
    }
  },

  async splitCartItem ({ dispatch }, { item }) {
    const condition = item.condition
    const language = item.language

    await runTask(splitBuylistCartItem({
      id: item.id,
      data: {
        offer: item.offer,
        condition,
        language,
        count: item.count - 1
      }
    }))
    return await dispatch('refreshCart')
  },

  async updateCartItem ({ commit, dispatch }, {
    offer,
    cartItemId,
    condition = 'NM',
    language = 'en',
    count = 1,
    noRefresh = false
  }) {
    const offerId = offer.id

    try {
      commit('SET_OPERATING', true)
      return await runTask(updateBuylistCartItem({
        id: cartItemId,
        data: {
          offer: offerId,
          count,
          condition,
          language
        }
      }))

    } catch (e) {
      LogService.error(e)
      throw e
    } finally {
      if (!noRefresh) {
        dispatch('refreshCart')
      }
    }
  },

  async removeItemFromCart ({ commit, dispatch, getters }, cartItemId) {
    try {
      const cart = {...getters.cart}
      cart.items = filter(cart.items, item => item.id !== cartItemId)
      commit('SET_CART', cart)

      await runTask(deleteBuylistCartItem({id: cartItemId}))
    } catch (e) {
      LogService.error(e)
    } finally {
      dispatch('refreshCart')
    }
  },

  async emptyCart ({ commit, dispatch }) {
    try {
      commit('SET_EMPTYING', true)
      commit('SET_OPERATING', true)
      await runTask(emptyBuylistCart())
    } catch (e) {
      LogService.error(e)
    } finally {
      commit('SET_EMPTYING', false)
      dispatch('refreshCart')
    }
  },

  setCartSeen({commit, getters}) {
    commit('SET_CART_SEEN', getters.cart.countPriceUpdated)
  }
}

export const getters = {
  getLocCurrency: (state, getters, rootState, rootGetters) => (loc) => decodeLoc(loc, rootGetters['general/getCurrencyLower']),

  cart: (state) => {
    return new BuyCart(state.cart || {})
  },
  isInitialized: (state, getters) => {
    return getters.cart instanceof BuyCart
  },
  isOperating: (state) => {
    return state.isOperating
  },
  isEmptying: (state) => {
    return state.isEmptying
  },

  cartItems: (state, getters) => {
    return map(getters.cart.items, item => new BuyCartItem(item))
  },
  cartLastSeen: (state) => {
    return state.cartLastSeen
  },

  hasPriceChange: (state, getters) => {
    return getters.cartLastSeen !== getters.cart.countPriceUpdated && getters.cartItems.some(item => item.countHasChanged || item.priceHasChanged)
  },
  showBulkBanner: (state, getters) => {
    const singles = filter(getters.cartItems, item => !item.isNonSingle)
    return singles.length > 4
  },
  buyupItemsCount: (state, getters) => {
    if (!getters.isInitialized) return 0

    return sumBy(getters.cartItems, 'count')
  },
  hasCartItems: (state, getters) => {
    return getters.buyupItemsCount > 0
  },
  buyupItemsTotal: (state, getters) => {
    return getters.isInitialized ? getters.getLocCurrency(getters.cart.userTotalPriceLoc) : 0
  },

  availableForBuyupByOffer: (state, getters) => (offerId) => {
    const items = filter(getters.cartItems, /** @type CartItem */ item => {
      return item.offer.id === offerId
    })
    return sumBy(items, 'count')
  }
}
