import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'

import LogService from '@/services/LogService'

import Article from '@/database/models/Article'
import WZGame from '@/database/models/WZGame'
import { findObject, keepKeysOnly } from '@/services/Helpers'
import Product from '@/database/models/Product'
import GameExpansion from '@/database/models/GameExpansion'
import MagicArtist from '@/database/models/MagicArtist'
import MagicSubtype from '@/database/models/MagicSubtype'
import FeaturedProductType from '@/structures/FeaturedProductType'
import BuyupOffer from '~/database/models/BuyupOffer'
import { serializeProductFilter } from '~/services/FilterService'
import { parseArray, runTask } from '~/services/network/utils/axios'
import {
  getExpansions,
  getGames,
  getMtgArtists,
  getMtgSubtypes,
  getNonsingles,
  getMtgSingles,
  getSingles,
} from '~/services/network/utils/catalog.convenience.axios'
import { getOffers } from '~/services/network/utils/buylist.convenience.axios'
import {
  getNonsingles as getNonsinglesWorker,
  getMtgSingles as getMtgSinglesWorker,
  getSingles as getSinglesWorker,
  getBuylistOffers as getBuylistOffersWorker,
  setNonsingleRating
} from '~/workers/network.worker'

const FeaturedType = FeaturedProductType

export const state = () => ({
  expansions: [],
  mtgSubtypes: [],
  mtgArtists: [],
  games: [],
  rarities: [],

  featured: {
    isOperating: false,
    [FeaturedType.new]: []
  }
})

export const mutations = {
  'SET_EXPANSIONS' (state, { data }) {
    if (data) {
      state.expansions = Object.freeze(data)
    }
  },
  'SET_SUBTYPES' (state, { data }) {
    if (data) {
      state.mtgSubtypes = Object.freeze(data)
    }
  },
  'SET_ARTISTS' (state, { data }) {
    if (data) {
      state.mtgArtists = Object.freeze(data)
    }
  },
  'SET_GAMES' (state, { data }) {
    if (data) {
      state.games = Object.freeze(data)
    }
  },
  'SET_RARITIES' (state, { data }) {
    if (data) {
      state.rarities = Object.freeze(data)
    }
  },

  'SET_FEATURED' (state, { type, items }) {
    state.featured[type] = Object.freeze(items)
  },
  'SET_FEATURED_OPERATING' (state, status) {
    state.featured.isOperating = status
  }
}

export const actions = {
  getNonSingles (_, { query, cancelId, worker = true }) {
    const serializedQuery = serializeProductFilter(query)
    let promise = null

    if(worker) {
      promise = runTask(getNonsinglesWorker({query: serializedQuery, cancelId}))
    } else {
      promise = getNonsingles({query: serializedQuery})
    }
    return parseArray(Article, promise)
  },
  getSingles (_, { query, cancelId, worker = true }) {
    const serializedQuery = serializeProductFilter(query, {})
    let promise = null

    if(worker) {
      promise = runTask(getSinglesWorker({query: serializedQuery, cancelId}))
    } else {
      promise = getSingles({query: serializedQuery})
    }
    return parseArray(Product, promise)
  },
  getMtgSingles (_, { query, cancelId, worker = true }) {
    const serializedQuery = serializeProductFilter(query)
    let promise = null

    if(worker) {
      promise = runTask(getMtgSinglesWorker({query: serializedQuery, cancelId}))
    } else {
      promise = getMtgSingles({query: serializedQuery})
    }
    return parseArray(Product, promise)
  },
  getBuylistOffers (_, { query, cancelId = null, worker = true }) {
    const serializedQuery = serializeProductFilter(query)
    let promise = null

    if(worker) {
      promise = runTask(getBuylistOffersWorker({query: serializedQuery, cancelId}))
    } else {
      promise = getOffers({query: serializedQuery})
    }
    return parseArray(BuyupOffer, promise)
  },

  async setNonSingleRating (_, { sku, value }) {
    return await runTask(setNonsingleRating({
      id: sku,
      data: {
        value
      }
    }))
  },

  async getMtgSubtypes ({ commit, rootGetters }, {mock = []}) {
    let subtypes = mock

    try {
      if(isEmpty(subtypes)) {
        subtypes = (await getMtgSubtypes()).data
      }

      subtypes = map(subtypes, subtype => {
        return {
          id: subtype,
          label: subtype,
          title: subtype,
          icon: ''
        }
      })
      commit('SET_SUBTYPES', { data: subtypes })
    } catch (e) {
      LogService.error(e)
    }
  },

  async getGames ({ commit, rootGetters }, {mock = []}) {
    let games = mock

    try {
      if(isEmpty(games)) {
        /** @type FetchResult */
        const result = await getGames({})
        games = result.data
      }

      commit('SET_GAMES', { data: games })
    } catch (e) {
      LogService.error(e)
    }
  },

  getRarities ({ commit, rootState }, {mock = []}) {
    commit('SET_RARITIES', { data: mock.map(rarity => {
        return {
          ...rarity,
          id: rarity.name,
          label: rarity.name,
          color: 'gray',
        }
      })
    })
  },

  async getExpansions ({ commit, rootGetters }, {mock = []}) {
    let expansions = mock

    try {
      if(isEmpty(expansions)) {
        /** @type {FetchResult} */
        const result = await getExpansions({
          query: {
            o: '-date'
          }
        })
        expansions = result.data
      }

      commit('SET_EXPANSIONS', {
        data: filter(expansions, expansion => expansion.appears_in_najada_filter)
      })
    } catch (e) {
      LogService.error(e)
    }
  },

  async getMtgArtists ({ commit, rootGetters }, {mock = []}) {
    let artists = mock

    try {
      if(isEmpty(artists)) {
        artists = (await getMtgArtists()).data
      }

      artists = map(artists, artist => {
        return {
          id: artist,
          label: artist,
          title: artist,
          icon: ''
        }
      })

      commit('SET_ARTISTS', { data: artists })
    } catch (e) {
      LogService.error(e)
    }
  },

  async getFeaturedProducts ({ dispatch }, {newItems = []}) {
    await dispatch('getFeaturedNewProducts', {mock: newItems})
  },

  async getFeaturedNewProducts ({ commit, rootGetters }, {mock = []}) {
    let newItems = mock
    try {
      if(isEmpty(newItems)) {
        newItems = (await getNonsingles({
          query: {
            in_stock: true,
            new: true,
            presale: false,
            product_category: Product.Category.nonSingleCards,
            limit: 20,
            offset: 0,
          }
        })).data
      }

      commit('SET_FEATURED', {
        type: FeaturedType.new,
        items: map(newItems, item => {
          return keepKeysOnly(item, [
            'id',
            'sku',
            'regular_price_czk',
            'regular_price_eur',
            'effective_price_czk',
            'effective_price_eur',
            'featured_discount',
            'product.is_new',
            'product.is_presale',
            'product.release_date',
            'product.name',
            'product.name_cz',
            'product.name_de',
            'product.image_url_small',
            'product.image_url_medium',
            'product.image_url',
            'image_url_small',
            'image_url',
          ])
        })
      })
    } catch (e) {
      LogService.error(e)
    }
  },
  async getFeaturedPresaleProducts ({ commit, rootGetters }) {
    let presaleItems = []

    try {
      presaleItems = (await getNonsingles({
        query: {
          presale: true,
          product_category: Product.Category.nonSingleCards,
          limit: 20,
          offset: 0,
        }
      })).data

      return presaleItems
    } catch (e) {
      LogService.error(e)
    }
  },
  async getFeaturedMonthSaleProducts ({ commit, rootGetters }) {
    let monthlyItems = []

    try {
      monthlyItems = (await getNonsingles({
        query: {
          discounted: true,
          featured_discount: true,
          product_category: Product.Category.nonSingleCards,
          limit: 20,
          offset: 0,
        }
      })).data

      return monthlyItems
    } catch (e) {
      LogService.error(e)
    }
  }
}

export const getters = {
  expansions: state => {
    return orderBy(state.expansions.map(item => new GameExpansion(item)), 'title')
  },
  expansionsForGame: (state, getters) => game => {
    return getters.expansions.filter(item => item.game === game)
  },
  nonMtgExpansions: (state) => {
    const expNonMtg = filter(state.expansions, item => {
      return !GameExpansion.isObjectMtgGame(item)
    })
    const mapped = map(expNonMtg, item => {
      return new GameExpansion(item)
    })
    return orderBy(mapped, 'title')
  },
  mtgExpansions: (state) => {
    const expMtg = filter(state.expansions, item => {
      return GameExpansion.isObjectMtgGame(item)
    })
    const mapped = map(expMtg, item => {
      return new GameExpansion(item)
    })
    return orderBy(mapped, 'title')
  },
  mtgSubtypes: (state) => {
    const subtypes = map(state.mtgSubtypes, subtype => new MagicSubtype(subtype))
    return orderBy(subtypes, 'title')
  },
  mtgArtists: (state) => {
    const artists = map(state.mtgArtists, artist => new MagicArtist(artist))
    return orderBy(artists, 'title')
  },
  games: (state) => {
    return map(state.games, game => new WZGame(game))
  },
  gamesGroupped: (state, getters) => {
    return groupBy(getters.games, 'customer_interest_type')
  },

  hasArtists: (state) => {
    return !isEmpty(state.mtgArtists)
  },
  hasExpansions: (state) => {
    return !isEmpty(state.expansions)
  },
  hasGames: (state) => {
    return !isEmpty(state.games)
  },
  hasSubtypes: (state) => {
    return !isEmpty(state.mtgSubtypes)
  },

  expansionForId: (state, getters) => id => {
    return findObject([...getters.nonMtgExpansions, ...getters.mtgExpansions], expansion => expansion.id === id)
  },
  raritiesForGame: (state, getters, rootState) => id => {
    let rarities = state.rarities.filter(rarity => rarity.gameIds.includes(id))
    const raritiesConfigs = rootState.configurations.configuration_singles?.rarities || []
    const rarityConfig = raritiesConfigs.find(item => item.game === id)

    if(rarityConfig) {
      rarities = rarities.map(rarity => {
        const item = rarityConfig.items.find(item => item.id === rarity.id) || {}

        return {
          ...rarity,
          ...item
        }
      })
    }

    return rarities
  },

  featuredNew: state => {
    return map(state.featured[FeaturedType.new], item => new Article(item))
  }
}
