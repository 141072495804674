import { configureScope } from '@sentry/browser'
import clone from 'lodash/clone'
import find from 'lodash/find'
import isNull from 'lodash/isNull'
import map from 'lodash/map'

import User from '@/database/models/User'
import ShippingAddress from '@/database/models/ShippingAddress'
import BillingAddress from '@/database/models/BillingAddress'
import LogService from '@/services/LogService'
import { findObject } from '@/services/Helpers'
import WZGame from '@/database/models/WZGame'
import { getAccessToken, hasToken, login, logout } from '~/services/FacebookService'
import { runTask, setToken, destroyToken } from '~/services/network/utils/axios'
import {
  getUserDetail,
  setToken as setTokenWorker,
  destroyToken as destroyTokenWorker,
  createUser,
  loginUser,
  logoutUser,
  convertOAuthToken,
  refreshOAuthToken,
  editUserDetail,
  changeUserEmail,
  changeUsername,
  setVoucher,
  deleteShippingAddress,
  deleteBillingAddress,
  deleteGameInterest,
  createGameInterest,
  changeUserPassword
} from '~/workers/network.worker'

export const state = () => ({
  authToken: null,
  refreshToken: null,
  tokenExpires: null,

  user: null
})

export const mutations = {
  'TOKEN_CHANGED' (state, { token, refreshToken = null, expires = null }) {
    state.authToken = token
    state.refreshToken = refreshToken
    state.tokenExpires = expires
    setToken(token)
    setTokenWorker(token)
  },
  'USER_CHANGED' (state, {user}) {
    state.user = user
  },
  'USER_LOGIN' (state, {user}) {
    state.user = user
  },
  'USER_LOGOUT' (state) {
    state.authToken = null
    state.refreshToken = null
    state.tokenExpires = null

    state.user = null
  }
}

export const actions = {
  registerUser (_, data) {
    return runTask(createUser({
      data: {
        username: data.username,
        password: data.password,
        first_name: data.firstname,
        last_name: data.lastname,
        email: data.email,
        phone: `${data.phone.code}${data.phone.number}`,
        currency: data.currency,
        agree_to_marketing_emails: Boolean(data.marketingEmails),
        agree_to_order_update_emails: Boolean(data.orderUpdateEmails),
        agree_to_terms_and_conditions: Boolean(data.termsAndConditions),
        game_interests: []
      }
    }))
  },
  async login ({ commit, dispatch }, { email, password }) {
    const token = await runTask(loginUser({
      data:{
        email,
        password
      }
    }))
    commit('TOKEN_CHANGED', {
      token: token.auth_token
    })

    await dispatch('general/hello', {}, { root: true })
    await dispatch('loadProfileDetail', {})
    dispatch('general/refreshCarts', {}, { root: true })
  },

  async socialLoginFacebook ({dispatch}, {appClientId}) {
    let fbToken = getAccessToken()
    let backend = 'facebook'

    if(isNull(fbToken)) {
      const { authResponse: { accessToken, graphDomain } } = await login()
      fbToken = accessToken
      backend = graphDomain
    }

    await dispatch('loginOAuth', {
      token: fbToken,
      backend,
      appClientId
    })
  },

  async loginOAuth ({commit, dispatch}, {token, backend = 'facebook', appClientId}) {
    // eslint-disable-next-line camelcase
    const { access_token: accessToken, refresh_token: refreshToken, expires_in: expiresIn } = await runTask(convertOAuthToken({
      data: {
        backend,
        token,
        grant_type: 'convert_token',
        client_id: appClientId
      }
    }))

    commit('TOKEN_CHANGED', {
      token: accessToken ? `Bearer ${accessToken}` : null,
      refreshToken,
      expires: Date.now() + (expiresIn * 1000)
    })

    // await dispatch('general/hello', {}, { root: true })
    await dispatch('loadProfileDetail', {})
    dispatch('general/refreshCarts', {}, { root: true })
  },

  async refreshOauth ({ commit }, { appClientId, refreshToken }) {
    const {
      access_token: newAccessToken,
      refresh_token: newRefreshToken,
      expires_in: expiresIn
    } = await refreshOAuthToken({
      data: {
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
        client_id: appClientId
      }
    })

    commit('TOKEN_CHANGED', {
      token: newAccessToken ? `Bearer ${newAccessToken}` : null,
      refreshToken: newRefreshToken,
      expires: Date.now() + (expiresIn * 1000)
    })
  },

  async loadProfileDetail ({ commit, dispatch, getters }, { updateDependencies = true }) {
    if (!getters.hasAuthToken) {
      dispatch('logout')
      return
    }

    try {
      const userDetail = await runTask(getUserDetail())
      commit('USER_LOGIN', {
        user: userDetail
      })
      commit('checkout/EMAIL_SET', userDetail.email, {root: true})
      const user = getters.user

      if (!user.isActive) return dispatch('logoutUnauthorized')

      // Set user to Sentry
      configureScope((scope) => {
        scope.setUser({ email: userDetail.email })
      })
      dispatch('general/setCurrency', user.currency, { root: true })

      if (updateDependencies) {
        dispatch('gamingClub/fetchPlayerProfiles', null, {root: true})
        dispatch('gamingClub/fetchUserTournaments', null, {root: true})
        await dispatch('updateUserDependencies')
      }

      if(user.primaryShippingAddressId === null) {
        commit('checkout/SHIPPING_ADDRESS_PARTIAL_SET', {
          first_name: user.firstname,
          last_name: user.lastname,
          ...(user.phone && { phone: user.phone })
        }, { root: true })
      }
    } catch (err) {
      if (('code' in err) && err.code === 401) {
        dispatch('logoutUnauthorized')
      }
      throw err
    }
  },
  async updateUser ({ commit, dispatch }, payload) {
    const userDetail = await runTask(editUserDetail({
      data: User.mapProperties(payload)
    }))
    commit('USER_CHANGED', {
      user: userDetail
    })

    await dispatch('updateUserDependencies')
  },

  async changeEmail ({ commit, getters }, { newEmail, currentPassword }) {
    await runTask(changeUserEmail({
      data: User.mapProperties({
        newEmail,
        currentPassword
      })
    }))

    const user = {...getters.user}
    user.email = newEmail

    commit('USER_CHANGED', {
      user
    })
  },

  async changePhone ({ commit, getters }, { phone }) {
    await runTask(editUserDetail({
      data: User.mapProperties({
        phone
      })
    }))
    const user = {...getters.user}
    user.phone = phone

    commit('USER_CHANGED', {
      user
    })
  },

  changeUserPassword (_, data) {
    return runTask(changeUserPassword({
      data: User.mapProperties(data)
    }))
  },

  async changeUsername ({ dispatch }, data) {
    await runTask(changeUsername({
      data
    }))
    await dispatch('loadProfileDetail', {
      updateDependencies: false
    })
  },

  async updateUserDependencies ({ commit, getters, dispatch }) {
    const userPhone = getters.userPhoneNumber
    dispatch('wantlist/fetchItems', {}, { root: true })
    dispatch('shoppingLists/getShoppingLists', {}, { root: true })
    await Promise.all([dispatch('getShippingAddresses'), dispatch('getBillingAddresses')])

    commit(
      'checkout/SHIPPING_ADDRESS_SET',
      { address: getters.primaryShippingAddress },
      { root: true }
    )
    commit(
      'checkout/BILLING_ADDRESS_SET',
      { address: getters.primaryBillingAddress },
      { root: true }
    )

    if(userPhone) {
      commit(
        'checkout/BILLING_ADDRESS_PARTIAL_SET',
        { phone: userPhone },
        { root: true }
      )
    }
  },

  async logout ({ commit, dispatch, getters }) {
    const isAuthenticated = getters.isAuthenticated

    if(isAuthenticated) {
      commit('USER_LOGOUT')

      try {
        await logoutUser()
      } catch (e) {
        LogService.log(e)
      }
    }

    commit('USER_LOGOUT')
    dispatch('gamingClub/onUserLogout', null, {root: true})
    destroyToken()
    destroyTokenWorker()

    if (process.client && hasToken()) {
      logout()
    }

    dispatch('checkout/resetModule', {}, { root: true })
    if(isAuthenticated) {
      dispatch('general/refreshCarts', {}, { root: true })
      dispatch('general/hello', {}, { root: true })
    }

    dispatch('clearAddresses')
    dispatch('shoppingLists/clear', {}, { root: true })
    dispatch('wantlist/removeAll', {}, { root: true })

    configureScope((scope) => {
      scope.setUser({ email: null })
    })

    // Doesn't work in dev mode
    if (this.app.router.currentRoute.matched.some(record => record.meta.requiresAuth)) {
      await this.app.router.replace({name: 'home'})
    } else if(this.app.router.currentRoute?.name?.includes('checkoutStep') && !this.app.router.currentRoute?.name?.includes('checkoutStep1')) {
      await this.app.router.replace({name: 'checkoutStep1__cs'})
    }
  },
  logoutUnauthorized ({ dispatch }) {
    dispatch('logout')
    this.app?.$modal?.showLogin()
  },
  async mountToken ({ getters }) {
    const token = getters.authToken
    await setToken(token)
  },

  clearAddresses ({ dispatch }) {
    dispatch('address/clearAddresses', {}, { root: true })
  },

  async updateGameInterests ({ dispatch }, { idsToDelete, idsToCreate }) {
    for (let i = 0; i < idsToDelete.length; i++) {
      await runTask(deleteGameInterest({id: idsToDelete[i]}))
    }

    for (let i = 0; i < idsToCreate.length; i++) {
      await runTask(createGameInterest({
        data: {
          game: idsToCreate[i]
        }
      }))
    }

    await dispatch('loadProfileDetail', { updateDependencies: false })
  },

  getShippingAddresses ({ dispatch }) {
    return dispatch('address/getShippingAddresses', {}, { root: true })
  },
  async createShippingAddress ({ commit, dispatch }, { address }) {
    const addressResult = await dispatch('address/createShippingAddress', {address}, {root: true})
    dispatch('shippingAddressChanged', {address: addressResult})
    await dispatch('general/hello', {}, { root: true })
    return new ShippingAddress(addressResult)
  },
  async updateShippingAddress ({ dispatch, getters }, {address}) {
    const result = await dispatch('address/updateShippingAddress', {address}, {root: true})
    dispatch('shippingAddressChanged', {address: result})
    await dispatch('general/hello', {}, { root: true })
    return dispatch('getShippingAddresses')
  },
  async deleteShippingAddress ({ commit, dispatch, getters, rootGetters }, {address}) {
    const addressId = address.id
    const checkoutShippingAddress = rootGetters['checkout/shippingAddress']

    await runTask(deleteShippingAddress({id: addressId}))

    await dispatch('loadProfileDetail', { updateDependencies: false })
    await dispatch('general/hello', {}, { root: true })
    await dispatch('getShippingAddresses')

    if(addressId === checkoutShippingAddress.id) {
      const primaryAddress = getters.primaryShippingAddress

      if (primaryAddress) {
        commit(
          'checkout/SHIPPING_ADDRESS_SET',
          { address: primaryAddress },
          { root: true }
        )
      } else {
        commit('checkout/SHIPPING_ADDRESS_SET', { address: new ShippingAddress() }, { root: true })
      }
    }
  },

  getBillingAddresses ({ dispatch }) {
    return dispatch('address/getBillingAddresses', {}, { root: true })
  },
  async createBillingAddress ({ commit, dispatch, getters }, { address }) {
    const result = await dispatch('address/createBillingAddress', {address}, {root: true})
    dispatch('billingAddressChanged', {address: result})
    return new BillingAddress(result)
  },
  async updateBillingAddress ({ dispatch, getters }, {address}) {
    const result = await dispatch('address/updateBillingAddress', {address}, {root: true})

    dispatch('billingAddressChanged', {address: result})
    return dispatch('getBillingAddresses')
  },
  async deleteBillingAddress ({ commit, dispatch, getters, rootGetters }, {address}) {
    const addressId = address.id
    const checkoutBillingAddress = rootGetters['checkout/billingAddress']

    await runTask(deleteBillingAddress({id: addressId}))

    await dispatch('loadProfileDetail', { updateDependencies: false })
    await dispatch('getBillingAddresses')

    if(addressId === checkoutBillingAddress.id) {
      const primaryAddress = getters.primaryBillingAddress

      if (primaryAddress) {
        commit(
          'checkout/BILLING_ADDRESS_SET',
          { address: primaryAddress },
          { root: true }
        )
      } else {
        commit('checkout/BILLING_ADDRESS_SET', { address: new BillingAddress() }, { root: true })
      }
    }
  },

  shippingAddressChanged({commit, getters, rootGetters}, {address}) {
    const primaryAddress = getters.primaryShippingAddress
    const checkoutAddress = rootGetters['checkout/shippingAddress']

    if(primaryAddress === null || (primaryAddress && primaryAddress.id === address.id) || (checkoutAddress && checkoutAddress.id === address.id)) {
      commit('checkout/SHIPPING_ADDRESS_SET', { address }, { root: true })
    }
  },
  billingAddressChanged({commit, getters, rootGetters}, {address}) {
    const primaryAddress = getters.primaryBillingAddress
    const checkoutAddress = rootGetters['checkout/billingAddress']

    if(primaryAddress === null || (primaryAddress && primaryAddress.id === address.id) || (checkoutAddress && checkoutAddress.id === address.id)) {
      commit('checkout/BILLING_ADDRESS_SET', { address }, { root: true })
    }
  },

  async setVoucher ({ dispatch }, { code }) {
    await runTask(setVoucher({
      data: {
        code
      }
    }))
    await dispatch('loadProfileDetail', {})
    await dispatch('cart/refreshCart', {}, { root: true })
  }
}

export const getters = {
  authToken: state => {
    return state.authToken
  },
  refreshToken: state => {
    return state.refreshToken
  },
  user: state => {
    return state.user ? new User(state.user) : null
  },
  hasAuthToken: state => {
    return state.authToken !== null
  },
  isAuthenticated: (state, getters) => {
    return getters.authToken !== null && getters.user !== null
  },
  isStaff: (state, getters) => {
    return getters.isAuthenticated && getters.user.isStaff
  },
  isFacebookConnected: (state, getters) => {
    return getters.isAuthenticated && getters.user.hasFacebook
  },
  agreeToMarketingEmails: (state, getters) => {
    return getters.isAuthenticated && getters.user.agreeToMarketingEmails
  },

  userPicture: (state, getters) => {
    return getters.isAuthenticated && getters.user.picture
  },
  userHasUnfinishedRegistration: (state, getters) => {
    return getters.isAuthenticated && getters.user.hasUnfinishedRegistration
  },
  userEmail: (state, getters) => {
    return getters.isAuthenticated ? getters.user.email : ''
  },
  userPhoneNumber: (state, getters) => {
    return getters.isAuthenticated ? getters.user.phone : ''
  },
  userFullName: (state, getters) => {
    return getters.isAuthenticated ? getters.user.fullName : ''
  },
  userUsername: (state, getters) => {
    return getters.isAuthenticated ? getters.user.username : ''
  },
  userGameInterests: (state, getters) => {
    return getters.isAuthenticated ? getters.user.gameInterests || [] : []
  },
  userCmsSubscriptionTitles: (state, getters) => {
    return getters.isAuthenticated ? getters.user.cmsTitleSubscription || [] : []
  },
  userGameInterestsIds: (state, getters) => {
    const games = getters.userGameInterests
    return map(games, game => game.game || game.id)
  },
  userGameInterestsPaired: (state, getters, rootState, rootGetters) => {
    const userGames = clone(getters.userGameInterests)
    const games = rootGetters['catalog/games']

    for (let i = 0; i < userGames.length; i++) {
      const userGame = userGames[i]
      const game = findObject(games, game => game.id === userGame.game)

      if (game) {
        userGames[i] = game
      }
    }

    return map(userGames, item => new WZGame(item))
  },

  // Loyalty
  userLoyaltyDiscountPercentage: (state, getters) => {
    return getters.isAuthenticated ? getters.user.loyaltyDiscountPercentage || 0 : 0
  },
  hasLoyaltyDiscount: (state, getters) => {
    return getters.userLoyaltyDiscountPercentage > 0
  },
  userLoyaltyLevel: (state, getters) => {
    return getters.isAuthenticated ? getters.user.loyaltyLevel : null
  },
  userLoyaltyProgressToNextLevel: (state, getters) => {
    return getters.isAuthenticated ? getters.user.loyaltyProgress : 0
  },
  userLoyaltyHasNextLevel: (state, getters) => {
    return getters.isAuthenticated && getters.user.loyaltyNextLevel !== null
  },
  userLoyaltyNextLevel: (state, getters) => {
    return getters.isAuthenticated ? getters.user.loyaltyNextLevel : null
  },

  // Credit
  hasCredit: (state, getters) => {
    return getters.isAuthenticated && getters.user.credit > 0
  },
  userTotalCredit: (state, getters) => {
    return getters.isAuthenticated ? getters.user.credit : 0
  },

  // League
  userLeaguePoints: (state, getters) => {
    return getters.isAuthenticated ? getters.user.leaguePoints : 0
  },
  userLeaguePosition: (state, getters) => {
    return getters.isAuthenticated ? getters.user.leaguePosition : 1
  },

  // Addresses
  shippingAddresses: (state, getters, rootState, rootGetters) => {
    return rootGetters['address/shippingAddresses']
  },
  primaryShippingAddress: (state, getters) => {
    if (!getters.isAuthenticated || !getters.user.primaryShippingAddressId) {
      return null
    }

    const id = getters.user.primaryShippingAddressId
    return find(getters.shippingAddresses, address => address.id === id)
  },

  billingAddresses: (state, getters, rootState, rootGetters) => {
    return rootGetters['address/billingAddresses']
  },
  primaryBillingAddress: (state, getters) => {
    if (!getters.isAuthenticated || !getters.user.primaryBillingAddressId) {
      return null
    }

    const id = getters.user.primaryBillingAddressId
    return find(getters.billingAddresses, address => address.id === id)

  }
}
