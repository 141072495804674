import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import isObject from 'lodash/isObject'
import map from 'lodash/map'

import BillingAddress from '@/database/models/BillingAddress'
import ShippingAddress from '@/database/models/ShippingAddress'
import ShippingMethod from '@/structures/ShippingMethod'
import PaymentMethod from '@/structures/PaymentMethod'
import Order from '@/database/models/Order'
import { runTask } from '~/services/network/utils/axios'
import { createOrder, getPaymentMethods, getShippingMethods, validateCart } from '~/workers/network.worker'
import { decodeLoc } from '~/structures/Localizable'

export const state = () => ({
  isInitialStorePickupAvailable: true,
  isInStorePickup: false,

  shippingAddress: null,
  billingAddress: null,
  identicalBillingAddress: true,
  shippingMethod: null,
  paymentMethod: null,
  shippingAccessPoint: null,
  note: '',
  email: null,
  password: '',
  username: '',
  shouldRegister: true,
  useCreditForPayment: false,
  agreements: {
    newsletter: true,
    conditions: false
  }
})

export const mutations = {
  'IN_STORE_PICKUP_SET' (state, value) {
    state.isInStorePickup = value
    state.isInitialStorePickupAvailable = false
  },
  'SHIPPING_ADDRESS_PARTIAL_SET' (state, payload = {}) {
    state.shippingAddress = {...state.shippingAddress, ...payload}
  },
  'SHIPPING_ADDRESS_SET' (state, { address = {}, email }) {
    state.shippingAddress = { ...address }

    if (!isNull(email)) {
      state.email = email
    }
  },
  'BILLING_ADDRESS_PARTIAL_SET' (state, payload = {}) {
    state.billingAddress = {...state.billingAddress, ...payload}
  },
  'BILLING_ADDRESS_SET' (state, { address = {}, email }) {
    state.billingAddress = { ...address }

    if (!isNull(email)) {
      state.email = email
    }
  },
  'SHIPPING_ACCESS_POINT_SET' (state, { id, name, street, city, country, zip, type, place }) {
    if (id) {
      state.shippingAccessPoint = { id, name, street, city, country, zip, type, place }
    } else {
      state.shippingAccessPoint = null
    }
  },
  'SHIPPING_METHOD_SET' (state, payload) {
    state.shippingMethod = payload.transport
  },
  'PAYMENT_METHOD_SET' (state, payload) {
    state.paymentMethod = payload.payment
  },
  'AGREEMENT_SET' (state, payload) {
    if (payload.type in state.agreements) {
      state.agreements[payload.type] = payload.value
    }
  },
  'PASSWORD_SET' (state, password) {
    state.password = password
  },
  'USERNAME_SET' (state, username) {
    state.username = username
  },
  'EMAIL_SET' (state, email) {
    state.email = email
  },
  'REGISTRATION_REQUEST_SET' (state, payload) {
    state.shouldRegister = payload
  },
  'CREDIT_PAYMENT_SET' (state, useCredit) {
    state.useCreditForPayment = useCredit
  },
  'NOTE_SET' (state, { content }) {
    state.note = content
  },
  'IDENTICAL_BILLING_ADDRESS_SET' (state, value) {
    state.identicalBillingAddress = value
  },


  'RESET_MODULE' (state) {

    state.isInitialStorePickupAvailable = true
    state.isInStorePickup = false
    state.identicalBillingAddress = true
    state.shippingMethod = null
    state.paymentMethod = null
    state.shippingAccessPoint = null
    state.note = ''
    state.email = null
    state.password = ''
    state.username = ''
    state.useCreditForPayment = false
    state.agreements = {
      newsletter: true,
      conditions: false
    }
  },

  'RESET_ADDRESSES' (state) {
    state.shippingAddress = null
    state.billingAddress = null
  }
}

export const actions = {
  setInStorePickup ({ commit }, value) {
    commit('IN_STORE_PICKUP_SET', value)
  },
  setShippingAccessPoint ({ commit }, { id, name, street, city, country, zip, place, type }) {
    commit('SHIPPING_ACCESS_POINT_SET', { id, name, street, city, country, zip, type, place })
  },
  setPaymentMethod ({ commit }, payload) {
    commit('PAYMENT_METHOD_SET', { payment: payload })
  },
  setShippingMethod ({ commit }, payload) {
    commit('SHIPPING_METHOD_SET', { transport: payload })
  },
  setPassword ({ commit }, { password, username }) {
    commit('PASSWORD_SET', password)
    commit('USERNAME_SET', username)
  },
  setRegistrationRequest ({ commit }, { register = false }) {
    commit('REGISTRATION_REQUEST_SET', register)
  },
  setShippingAddress ({ commit }, { address, email = null }) {
    commit('SHIPPING_ADDRESS_SET', {
      email,
      address
    })
  },
  setBillingAddress ({ commit }, { address, email = null }) {
    commit('BILLING_ADDRESS_SET', {
      email,
      address
    })
  },
  setAgreement ({ commit }, payload) {
    commit('AGREEMENT_SET', payload)
  },
  setUseCredit ({ commit }, { useCredit = false }) {
    commit('CREDIT_PAYMENT_SET', useCredit)
  },
  setNote ({ commit }, { content }) {
    commit('NOTE_SET', { content })
  },
  setIdenticalBillingAddress ({ commit }, value) {
    commit('IDENTICAL_BILLING_ADDRESS_SET', value)
  },

  async getShippingMethods ({ getters, dispatch }, { cancelId = null }) {
    let shippingAddress = getters.shippingAddress
    const isInStorePickup = getters.isInStorePickup

    if(isInStorePickup) {
      shippingAddress = {
        first_name: getters.isAuthenticated ? getters.user.firstname : shippingAddress.firstname,
        last_name: getters.isAuthenticated ? getters.user.lastname : shippingAddress.lastname,
        phone: getters.isAuthenticated ? (getters.user.phone || shippingAddress.phone) : shippingAddress.phone,
        email: getters.email,
        country: 'CZ'
      }
    }

    let methods = await runTask(getShippingMethods({
      query: {
        shipping_address: shippingAddress
      },
      cancelId
    }))
    methods = map(methods.data, method => new ShippingMethod(method))

    if(!isInStorePickup) {
      methods = filter(methods, method => method.type !== ShippingMethod.Type.IN_STORE_PICKUP)
    }

    const methodIds = map(methods, method => method.id)

    if(!methodIds.includes(getters.shippingMethod?.id)) {
      dispatch('setShippingMethod', null)
    }

    return methods
  },

  async getPaymentMethods ({ getters }, { cancelId = null }) {
    const shippingAccessPoint = getters.shippingAccessPoint
    let shippingAddress = getters.shippingAddress

    if(getters.isInStorePickup) {
      shippingAddress = {
        first_name: getters.isAuthenticated ? getters.user.firstname : shippingAddress.firstname,
        last_name: getters.isAuthenticated ? getters.user.lastname : shippingAddress.lastname,
        phone: getters.isAuthenticated ? (getters.user.phone || shippingAddress.phone) : shippingAddress.phone,
        country: 'CZ'
      }
    }

    const data = {
      currency: 'CZK',
      email: getters.email,
      shipping_method: getters.shippingMethod?.id,
      shipping_address: shippingAddress,
      use_credit_for_payment: getters.useCreditForPayment
    }

    if (getters.shippingMethod?.needsAccessPointInfo && isObject(shippingAccessPoint)) {
      data.shipping_access_point = isNaN(shippingAccessPoint.id) ? shippingAccessPoint.id : +shippingAccessPoint.id
    }

    const methods = await runTask(getPaymentMethods({
      query: {
        ...data
      },
      cancelId
    }))
    return map(methods.data, method => new PaymentMethod(method))
  },

  async validateCart ({ getters }) {
    const isAuthenticated = getters.isAuthenticated
    const isInStorePickup = getters.isInStorePickup
    const shouldRegisterAccount = !isAuthenticated && getters.shouldRegister
    const username = getters.username
    const shippingAddress = getters.shippingAddress
    const ignoreIdenticalAddressSwitch = isAuthenticated && !isInStorePickup
    const cartData = {
      email: getters.email,
      register_user_account: shouldRegisterAccount,
      ...(shouldRegisterAccount && !isEmpty(username) && {username}),
      shipping_address: shippingAddress,
      ...(!getters.isAuthenticated && !isEmpty(getters.username) && {username: getters.username})
    }

    if(isInStorePickup) {
      cartData.shipping_address = {
        first_name: shippingAddress.firstname,
        last_name: shippingAddress.lastname,
        phone: shippingAddress.phone
      }
    }

    if ((!ignoreIdenticalAddressSwitch && getters.isIdenticalBillingAddress) && !getters.billingAddress.isValid) {
      cartData.billing_address = getters.shippingAddress
    } else {
      cartData.billing_address = getters.billingAddress
    }

    if(isInStorePickup && getters.isIdenticalBillingAddress) {
      delete cartData.billing_address
    }

    await runTask(validateCart({data: cartData}))
  },

  async createOrder ({ getters }) {
    const isAuthenticated = getters.isAuthenticated
    const isIdenticalBillingAddress = getters.isIdenticalBillingAddress
    const isInStorePickup = getters.isInStorePickup
    const ignoreIdenticalAddressSwitch = isAuthenticated && !isInStorePickup
    const billingAddress = getters.billingAddress
    let shippingAddress = getters.shippingAddress

    if(isInStorePickup) {
      shippingAddress = {
        first_name: shippingAddress.firstname,
        last_name: shippingAddress.lastname,
        phone: shippingAddress.phone,
        country: 'CZ'
      }
    }

    const config = {
      shippingAddress,
      shippingMethod: getters.shippingMethod.id,
      noteFromCustomer: getters.note,
      agreeToMarketingEmails: getters.newsletterAgreement,
      agreeToTermsAndConditions: getters.conditionsAgreement,
      useCreditForPayment: getters.useCreditForPayment
    }

    if (!isNull(getters.paymentMethod) && isObject(getters.paymentMethod) && 'id' in getters.paymentMethod) {
      config.paymentMethod = getters.paymentMethod.id
    }

    // If access point ID is needed for selected shipping method
    if (getters.shippingMethod.needsAccessPointInfo && isObject(getters.shippingAccessPoint) && getters.shippingMethod.type === getters.shippingAccessPoint.type) {
      config.shippingAccessPoint = getters.shippingAccessPoint.id
    }

    // If billing address is identical to shipping address
    if ((!ignoreIdenticalAddressSwitch && isIdenticalBillingAddress) || (!billingAddress.isValid && !isInStorePickup)) {
      config.billingAddress = { ...shippingAddress }
    } else {
      config.billingAddress = { ...billingAddress }
    }
    delete billingAddress.phone

    if (config.billingAddress.country !== 'CZ') {
      delete config.billingAddress.company_id
      delete config.billingAddress.company_tax_id
      delete config.billingAddress.company_name
    }

    // If user is anonymous, email is required
    if (!isNull(getters.email)) {
      config.email = getters.email
    }

    // If should register user upon checkout
    const shouldRegisterAccount = !isAuthenticated && getters.shouldRegister
    if (shouldRegisterAccount && !isNull(getters.password)) {
      config.newUserAccountPassword = getters.password
      config.registerUserAccount = getters.shouldRegister
      const username = getters.username

      if(!isEmpty(username)) {
        config.username = username
      }
    }

    return await runTask(createOrder({ data: Order.mapProperties(config) }))
  },

  resetModule ({ commit, getters, dispatch }) {
    commit('RESET_MODULE')
    dispatch('checkoutBuyup/resetModule', {}, { root: true })
    if (!getters.isAuthenticated) {
      commit('RESET_ADDRESSES')
    }
  }
}

export const getters = {
  isAuthenticated: (state, getters, rootState, rootGetters) => {
    return rootGetters['authentication/isAuthenticated']
  },
  user: (state, getters, rootState, rootGetters) => {
    return rootGetters['authentication/user']
  },

  isInStorePickup: state => {
    return state.isInStorePickup
  },
  isInitialStorePickupAvailable: state => {
    return state.isInitialStorePickupAvailable
  },
  paymentMethod: (state) => {
    if (isNull(state.paymentMethod)) return null
    return new PaymentMethod(state.paymentMethod)
  },
  paymentMethodPrice: (state, getters, rootState, rootGetters) => {
    const paymentMethod = getters.paymentMethod

    if (paymentMethod) {
      return decodeLoc(paymentMethod.priceLoc, rootGetters['general/getCurrencyLower'])
    }

    return 0
  },
  shippingMethod: (state) => {
    if (isNull(state.shippingMethod)) return null
    return new ShippingMethod(state.shippingMethod)
  },
  shippingMethodPrice: (state, getters, rootState, rootGetters) => {
    const shippingMethod = getters.shippingMethod

    if (shippingMethod) {
      return decodeLoc(shippingMethod.priceLoc, rootGetters['general/getCurrencyLower'])
    }

    return 0
  },
  shippingAccessPoint: (state) => {
    return state.shippingAccessPoint
  },

  isCashOnDeliveryDisabled: (state, getters, rootState, rootGetters) => {
    const currency = rootGetters['general/getCurrency']
    const address = getters.shippingAddress

    return currency === 'EUR' && (address.isValid && address.country === 'CZ')
  },

  newsletterAgreement: (state) => {
    return state.agreements.newsletter
  },
  conditionsAgreement: (state) => {
    return state.agreements.conditions
  },
  shippingAddress: (state) => {
    if (state.shippingAddress instanceof ShippingAddress) {
      return state.shippingAddress
    } else if (state.shippingAddress instanceof Object) {
      return new ShippingAddress(state.shippingAddress)
    } else {
      return new ShippingAddress()
    }
  },
  billingAddress: (state) => {
    if (state.billingAddress instanceof BillingAddress) {
      return state.billingAddress
    } else if (state.billingAddress instanceof Object) {
      return new BillingAddress(state.billingAddress)
    } else {
      return new BillingAddress()
    }
  },
  isShippingAddressValid: (state, getters) => {
    return getters.shippingAddress.isValid
  },
  isIdenticalBillingAddress: state => {
    return state.identicalBillingAddress
  },
  note: state => {
    return state.note || ''
  },
  email: (state, getters, rootState, rootGetters) => {
    return getters.isAuthenticated ? rootGetters['authentication/userEmail'] : state.email
  },
  password: (state) => {
    return state.password
  },
  username: (state) => {
    return state.username
  },
  shouldRegister: (state) => {
    return state.shouldRegister
  },

  useCreditForPayment: (state, getters, rootState, rootGetters) => {
    return getters.isAuthenticated ? (rootGetters['authentication/hasCredit'] && state.useCreditForPayment) : false
  },
  hasEnoughCreditForPayment: (state, getters, rootState, rootGetters) => {
    return getters.isAuthenticated ? (rootGetters['authentication/userTotalCredit'] >= rootGetters['cart/userPriceWShipping']) : false
  },
  creditAmountAfterOrder: (state, getters, rootState, rootGetters) => {
    const credit = rootGetters['authentication/userTotalCredit']
    const userPriceWShipping = rootGetters['cart/userPriceWShipping']

    let amount = credit - userPriceWShipping

    if (getters.useCreditForPayment) {
      const userPriceWShippingPayment = rootGetters['cart/userPriceWShippingPayment']
      amount = credit - userPriceWShippingPayment
    }

    return Math.max(amount, 0)
  },

  isBillingAddressValid: (state, getters) => {
    let isValid = getters.billingAddress.isValid

    if (!getters.isAuthenticated) {
      if (getters.isIdenticalBillingAddress) {
        isValid = true
      } else {
        isValid = getters.billingAddress.isValid
      }
    }

    return isValid
  },

  canAccessStep: (state, getters, rootState, rootGetters) => (index) => {
    const isAuthenticated = getters.isAuthenticated
    const shippingAddress = getters.shippingAddress
    const priceCheck = rootGetters['cart/userPriceCzk'] >= 25
    const hasItems = rootGetters['cart/cartItemsCount'] > 0

    let shippingAddressValid = shippingAddress.isValid
    let billingAddressValid = getters.isBillingAddressValid

    if (getters.isInStorePickup) {
      shippingAddressValid = (isAuthenticated || (shippingAddress.isValidForStorePickup && !isEmpty(getters.email)))
      billingAddressValid = isAuthenticated || billingAddressValid
    }

    const rules = [
      () => {
        return true
      },
      () => {
        return hasItems && priceCheck
      },
      () => {

        let creditPaymentValid = true

        if (getters.useCreditForPayment) {
          creditPaymentValid = getters.hasEnoughCreditForPayment || getters.paymentMethod !== null
        }

        let paymentCondition = (getters.paymentMethod !== null || getters.useCreditForPayment)
        if (rootGetters['cart/userPriceWShipping'] < 0) {
          paymentCondition = true
        }

        return hasItems &&
          priceCheck &&
          creditPaymentValid &&
          paymentCondition &&
          getters.shippingMethod !== null &&
          (!getters.shippingMethod.needsAccessPointInfo ? true : isObject(getters.shippingAccessPoint) && getters.shippingMethod.type === getters.shippingAccessPoint.type) &&
          shippingAddressValid &&
          billingAddressValid
      },
      () => {
        return state.agreements.conditions === true
      }
    ]

    if (index >= rules.length) return false

    return rules[index]()
  }
}
