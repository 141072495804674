import NavigationPage from '~/database/models/NavigationPage'
import LogService from '~/services/LogService'
import { getAppMenu } from '~/services/network/utils/helper.convenience.axios'

export const state = () => ({
  menu: [],
  page: null,
})

export const mutations = {
  SET_MENU (state, menu) {
    state.menu = menu
  },
  SET_PAGE (state, page) {
    state.page = page
  },
}

export const actions = {
  async getMenu ({ commit }) {
    try {
      const result = await getAppMenu({})
      commit('SET_MENU', result.data)
    } catch (e) {
      LogService.error(e)
    }
  }
}

export const getters = {
  getMenu: (state) => url => {
    return state.menu.find(item => [item.url_fragment, item.url_fragment_cz].includes(url))
  },
  hasPage: state => {
    return state.page !== null
  },
  page: state => {
    return state.page ? new NavigationPage(state.page) : null
  },
}
