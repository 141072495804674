import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import isEmpty from 'lodash/isEmpty'

import HomepageTitle from '@/database/models/HomepageTitle'

import CMSBanner from '@/database/models/CMSBanner'

import LogService from '@/services/LogService'
import { getBanners } from '~/services/network/utils/banner.convenience.axios'
import { getHomepageTitles } from '~/services/network/utils/catalog.convenience.axios'

export const state = () => ({
  [CMSBanner.BannerType.homepageMain]: [],
  [CMSBanner.BannerType.homepageSide]: [],
  [CMSBanner.BannerType.homepageContent]: [],
  [CMSBanner.BannerType.playhouse]: [],
  homepageTitles: []
})

export const mutations = {
  'BANNERS_SET' (state, { main, side, homepageContent }) {
    if (main) {
      state[CMSBanner.BannerType.homepageMain] = main
    }
    if (side) {
      state[CMSBanner.BannerType.homepageSide] = side
    }
    if (homepageContent) {
      state[CMSBanner.BannerType.homepageContent] = homepageContent
    }
  },
  'SET_TITLES' (state, titles = []) {
    state.homepageTitles = Object.freeze(titles)
  }
}

export const actions = {
  async getHomepageTitles ({ commit }, {mock = []}) {
    let titles = mock

    try {
      if(isEmpty(titles)) {
        const result = await getHomepageTitles({ query: {} })
        titles = result.data
      }

      commit('SET_TITLES', titles)
    } catch (e) {
      LogService.error(e)
    }
  },

  async getBanners ({ rootGetters, commit }, {mainMobile = [], mainDesktop = [], side = [], content = []}) {
    let homepageMain = []
    let homepageMainDesktop = mainDesktop
    let homepageMainMobile = mainMobile
    let homepageSide = side
    let homepageContent = content

    try {
      if(isEmpty(homepageMainDesktop)) {
        homepageMainDesktop = (await getBanners({
          query: {
            banner_type: CMSBanner.BannerType.homepageMain,
            desktop: true
          }
        })).data
      }
      if(isEmpty(homepageMainMobile)) {
        homepageMainMobile = (await getBanners({
          query: {
            banner_type: CMSBanner.BannerType.homepageMain,
            mobile: true
          }
        })).data
      }

      const hpMainDesktopIds = map(homepageMainDesktop, banner => banner.id)
      const hpMainMobileIds = map(homepageMainMobile, banner => banner.id)
      const homepageMainUnique = uniqBy([...homepageMainDesktop, ...homepageMainMobile], 'id')
      homepageMain = map(homepageMainUnique, banner => {
        banner.allowedOnDesktop = hpMainDesktopIds.includes(banner.id)
        banner.allowedOnMobile = hpMainMobileIds.includes(banner.id)
        return banner
      })
      commit('BANNERS_SET', {
        main: sortBy(homepageMain || [], 'slider_priority')
      })

      if(isEmpty(homepageSide)) {
        homepageSide = (await getBanners({
          query: { banner_type: CMSBanner.BannerType.homepageSide }
        })).data
      }

      commit('BANNERS_SET', {
        side: sortBy(homepageSide || [], 'slider_priority')
      })

      if(isEmpty(homepageContent)) {
        homepageContent = (await getBanners({ query: { banner_type: CMSBanner.BannerType.homepageContent } })).data
      }
      commit('BANNERS_SET', {
        homepageContent: sortBy(homepageContent || [], 'slider_priority')
      })
    } catch (e) {
      LogService.error(e)
    }
  }
}

export const getters = {
  homepageTitles: state => {
    return map(state.homepageTitles, item => new HomepageTitle(item))
  },
  homepageMain: state => {
    return map(state[CMSBanner.BannerType.homepageMain] || [], item => new CMSBanner(item))
  },
  homepageSide: state => {
    return map(state[CMSBanner.BannerType.homepageSide] || [], item => new CMSBanner(item))
  },
  homepageSideTwo: (state, getters) => {
    const banners = getters.homepageSide

    if (banners.length <= 2) {
      return banners
    }

    return banners.slice(0, 2)
  },
  homepageContent: state => {
    return map(state[CMSBanner.BannerType.homepageContent] || [], item => new CMSBanner(item))
  }
}
