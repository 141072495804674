import find from 'lodash/find'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import GCFormat from '~/database/models/GCFormat'
import GCGame from '~/database/models/GCGame'
import GCLeague from '~/database/models/GCLeague'
import GCPlayerProfile from '~/database/models/GCPlayerProfile'
import LogService from '~/services/LogService'
import { runTask } from '~/services/network/utils/axios'
import { getGCFormats, getGCGames, getLeagues, tournamentDisenroll, tournamentEnroll, getGCPlayerProfileMe } from '~/workers/network.worker'
import {getTournaments} from "~/services/network/utils/gamingClub.convenience.axios";
import {dayMonthYearISO} from "~/services/DateService";
import GCTournament from "~/database/models/GCTournament";

export const state = () => ({
  games: [],
  formats: [],

  leagues: [],

  playerProfiles: [],

  hasEnrolledTournaments: false,
  isFetchingDependencies: false,
})

export const mutations = {
  'SET_HAS_TOURNAMENTS': (state, value) => {
    state.hasEnrolledTournaments = value
  },
  'SET_GAMES': (state, items) => {
    state.games = items
  },
  'SET_FORMATS': (state, items) => {
    state.formats = items
  },

  'SET_LEAGUES': (state, items) => {
    state.leagues = items
  },

  'SET_PLAYER_PROFILES': (state, items) => {
    state.playerProfiles = items
  },

  'SET_FETCHING': (state, value) => {
    state.isFetchingDependencies = value
  }
}

export const actions = {
  async fetchDependencies({commit, dispatch, getters, state}) {
    if(state.isFetchingDependencies) return
    try {
      commit('SET_FETCHING', true)
      await Promise.all([
        dispatch('fetchFormats'),
        dispatch('fetchGames'),
        dispatch('fetchLeagues'),
        ...(getters.isAuthenticated ? [dispatch('fetchPlayerProfiles')] : []),
        ...(getters.isAuthenticated ? [dispatch('fetchUserTournaments')] : [])
      ])
    } catch (e) {
      LogService.error(e)
    } finally {
      commit('SET_FETCHING', false)
    }
  },

  async fetchFormats({commit}) {
    let hasMore = true
    let page = 0
    const limit = 20
    const items = []

    while (hasMore) {
      try {
        /** @type {FetchResult} */
        const result = await runTask(getGCFormats({
          cancelId: 'vuex-gamingclub-formats',
          query: {
            limit,
            offset: limit * page
          }
        }))
        page++
        hasMore = result.hasMore
        items.push(...result.data)
        commit('SET_FORMATS', [...items])
      } catch (e) {
        hasMore = false
        LogService.error(e)
      }
    }
  },

  async fetchGames({commit}) {
    let hasMore = true
    let page = 0
    const limit = 20
    const items = []

    while (hasMore) {
      try {
        /** @type {FetchResult} */
        const result = await runTask(getGCGames({
          cancelId: 'vuex-gamingclub-games',
          query: {
            limit,
            offset: limit * page
          }
        }))
        page++
        hasMore = result.hasMore
        items.push(...result.data)
        commit('SET_GAMES', sortBy([...items], 'order'))
      } catch (e) {
        hasMore = false
        LogService.error(e)
      }
    }
  },

  async fetchLeagues({commit}){
    try {
      const result = await runTask(getLeagues({cancelId: 'leagues-vuex-worker'}))
      commit('SET_LEAGUES', result.data)
    } catch (e) {
      LogService.error(e)
    }
  },

  async fetchPlayerProfiles({commit}){
    try {
      const result = await runTask(getGCPlayerProfileMe({cancelId: 'leagues-vuex-worker'}))
      commit('SET_PLAYER_PROFILES', result.data)
    } catch (e) {
      LogService.error(e)
    }
  },

  async fetchUserTournaments({commit}) {
    try {
      const tournaments = await getTournaments({
        query: {
          enrolled: true,
          date_after: dayMonthYearISO(),
          status: GCTournament.eligibleStatus(),
          limit: 1
        }
      })

      commit('SET_HAS_TOURNAMENTS', tournaments.data.length > 0)
    } catch (e) {
      LogService.error(e)
    }
  },

  async enrollPlayer({dispatch}, {id}) {
    const result = await runTask(tournamentEnroll({id}))
    dispatch('fetchUserTournaments')
    return result
  },
  async disenrollPlayer({dispatch}, {id}) {
    const result = await runTask(tournamentDisenroll({id}))
    dispatch('fetchUserTournaments')
    return result
  },

  onUserLogout({commit}) {
    commit('SET_PLAYER_PROFILES', [])
  }
}

export const getters = {
  isAuthenticated: (state, getters, rootState, rootGetters) => {
    return rootGetters['authentication/isAuthenticated']
  },
  hasEnrolledTournaments: state => {
    return state.hasEnrolledTournaments
  },
  formats: state => {
    return map(state.formats, format => new GCFormat(format))
  },
  games: state => {
    const games = map(state.games, format => new GCGame(format))
    return sortBy(games, game => game.order)
  },

  hasAnyPlayerProfile: state => {
    return state.playerProfiles.length > 0
  },
  playerProfiles: state => {
    return map(state.playerProfiles, profile => new GCPlayerProfile(profile))
  },
  playerProfilesIds: (state, getters) => {
    return map(getters.playerProfiles, 'id')
  },
  playerProfilesForGames: (state, getters) => {
    return map(getters.playerProfiles, profile => profile.gameObject.id)
  },
  playerProfileForGame: (state, getters) => game => {
    return find(getters.playerProfiles, profile => profile.gameObject.id === game)
  },
  hasPlayerProfileForGame: (state, getters) => game => {
    return getters.playerProfilesForGames.includes(game)
  },

  leagues: state => {
    return sortBy(map(state.leagues, league => new GCLeague(league)), 'id')
  },
  leagueForId: (state, getters) => id => {
    return find(getters.leagues, league => league.id === +id)
  }
}
