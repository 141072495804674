import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

import ShippingMethod from '@/structures/ShippingMethod'
import PaymentMethod from '@/structures/PaymentMethod'
import Order from '@/database/models/Order'
import { runTask } from '~/services/network/utils/axios'
import { isEUCountry } from '~/structures/MockSource'
import { createBuylistOrder, validateBuylistCart } from '~/workers/network.worker'

export const state = () => ({
  isInitialStorePickupAvailable: true,
  isInStorePickup: false,

  agreements: {
    newsletter: true,
    conditions: false,
    payment: false
  },
  bankAccount: '',
  bankAccountBic: '',
  paypalAccount: '',
  shippingMethod: null,
  paymentMethod: null
})

export const mutations = {
  'IN_STORE_PICKUP_SET' (state, value) {
    state.isInStorePickup = value
    state.isInitialStorePickupAvailable = false
  },

  'AGREEMENT_SET' (state, payload) {
    if (payload.type in state.agreements) {
      state.agreements[payload.type] = payload.value
    }
  },

  'RESET_MODULE' (state) {
    state.agreements = {
      newsletter: true,
      conditions: false,
      payment: false
    }
    state.bankAccount = ''
    state.paypalAccount = ''
    state.bankAccountBic = ''
  },

  'SET_ACCOUNT' (state, { type, value }) {
    if (type in state) {
      state[type] = value
    }
  },

  'SHIPPING_METHOD_SET' (state, { transport }) {
    state.shippingMethod = transport
  },
  'PAYMENT_METHOD_SET' (state, { payment }) {
    state.paymentMethod = payment
  }
}

export const actions = {
  setInStorePickup ({ commit, rootGetters }, value) {
    const billingAddress = rootGetters['checkout/billingAddress']
    const userPhoneNumber = rootGetters['authentication/userPhoneNumber']
    commit('IN_STORE_PICKUP_SET', value)

    if(value && (isNull(billingAddress.phone) || isEmpty(billingAddress.phone) || billingAddress.phone.length < 6)) {
      commit('checkout/BILLING_ADDRESS_PARTIAL_SET', {phone: userPhoneNumber}, {root: true})
    } else if(!value) {
      commit('checkout/BILLING_ADDRESS_PARTIAL_SET', {phone: ''}, {root: true})
    }
  },

  setAgreement ({ commit }, payload) {
    commit('AGREEMENT_SET', payload)
  },

  async validateCart ({getters, rootGetters}) {
    const isShippingIdentical = !getters.isAuthenticated && rootGetters['checkout/isIdenticalBillingAddress']
    const isInStorePickup = getters.isInStorePickup

    const billingAddress = rootGetters['checkout/billingAddress']
    const shippingAddress = isShippingIdentical ? billingAddress : rootGetters['checkout/shippingAddress']
    const email = rootGetters['checkout/email']
    const username = rootGetters['checkout/username']
    const paymentType = getters.paymentMethod.id
    const paypalAccount = getters.paypalAccount
    const bankAccountNumber = getters.bankAccount
    const bankSwift = getters.bankAccountBic
    const shouldRegister = getters.isAuthenticated ? false : rootGetters['checkout/shouldRegister']
    const password = getters.isAuthenticated ? null : rootGetters['checkout/password']

    let config = {
      phone: billingAddress.phone || shippingAddress.phone,
      billingFirstName: billingAddress.firstname,
      billingLastName: billingAddress.lastname,

      agreeToMarketingEmails: true,
      agreeToTermsAndConditions: true,
      paymentType, // PayPal, Bank Transfer, Credits, Cash
      deliveryMethod: getters.shippingMethod.id, // IN_PERSON, CARRIER
      customerNotifySettings: 'ALWAYS' // NEVER, ALWAYS, THRESHOLD_5, THRESHOLD_10, THRESHOLD_20
      // noteFromCustomer: ''  // TODO: Add real note
    }

    if(!isInStorePickup) {
      config = {
        ...config,
        billingStreet: billingAddress.street,
        billingStreet2: billingAddress.street2 || '',
        billingZip: billingAddress.zip,
        billingCity: billingAddress.city,
        billingCountry: billingAddress.country,

        shippingFirstName: shippingAddress.firstname,
        shippingLastName: shippingAddress.lastname,
        shippingStreet: shippingAddress.street,
        shippingStreet2: shippingAddress.street2 || '',
        shippingZip: shippingAddress.zip,
        shippingCity: shippingAddress.city,
        shippingCountry: shippingAddress.country
      }
    }

    // If anonymous user
    if (!isNull(email)) {
      config.email = email
    }

    // If should register user upon checkout
    if (shouldRegister && !isNull(password)) {
      config.registerUserAccount = shouldRegister
      config.newUserAccountPassword = password

      if(!isEmpty(username)) {
        config.username = username
      }
    }

    // Add paypal credentials if PayPal set as payment method
    if (paymentType === 'PayPal' && !isNull(paypalAccount)) {
      config.paypalEmail = paypalAccount
    }

    // If company set
    if (!isEmpty(billingAddress.companyName) && !isInStorePickup) {
      config.billingCompany = billingAddress.companyName
    }
    // If company set
    if (!isEmpty(shippingAddress.companyName) && !isInStorePickup) {
      config.shippingCompany = shippingAddress.companyName
    }

    if (isEmpty(config.billingStreet2)) {
      delete config.billingStreet2
    }
    if (isEmpty(config.shippingStreet2)) {
      delete config.shippingStreet2
    }

    // If payment method is set through PayPal
    if (paymentType === 'Bank Transfer' && !isNull(bankAccountNumber) && !isNull(bankSwift)) {
      config.bankAccountNumber = bankAccountNumber
      config.swift = bankSwift
    }

    return await runTask(validateBuylistCart({data: Order.mapProperties(config)}))
  },

  async createOrder ({ getters, rootGetters }) {
    const isShippingIdentical = !getters.isAuthenticated && rootGetters['checkout/isIdenticalBillingAddress']
    const isInStorePickup = getters.isInStorePickup

    const billingAddress = rootGetters['checkout/billingAddress']
    const shippingAddress = isShippingIdentical ? billingAddress : rootGetters['checkout/shippingAddress']
    const email = rootGetters['checkout/email']
    const username = rootGetters['checkout/username']
    const paymentType = getters.paymentMethod.id
    const paypalAccount = getters.paypalAccount
    const bankAccountNumber = getters.bankAccount
    const bankSwift = getters.bankAccountBic
    const shouldRegister = getters.isAuthenticated ? false : rootGetters['checkout/shouldRegister']
    const password = getters.isAuthenticated ? null : rootGetters['checkout/password']

    let config = {
      phone: billingAddress.phone || shippingAddress.phone,
      billingFirstName: billingAddress.firstname,
      billingLastName: billingAddress.lastname,

      agreeToMarketingEmails: getters.newsletterAgreement,
      agreeToTermsAndConditions: getters.conditionsAgreement,
      paymentType, // PayPal, Bank Transfer, Credits, Cash
      deliveryMethod: getters.shippingMethod.id, // IN_PERSON, CARRIER
      customerNotifySettings: 'ALWAYS' // NEVER, ALWAYS, THRESHOLD_5, THRESHOLD_10, THRESHOLD_20
      // noteFromCustomer: ''  // TODO: Add real note
    }

    if(!isInStorePickup) {
      config = {
        ...config,
        billingStreet: billingAddress.street,
        billingStreet2: billingAddress.street2 || '',
        billingZip: billingAddress.zip,
        billingCity: billingAddress.city,
        billingCountry: billingAddress.country,

        shippingFirstName: shippingAddress.firstname,
        shippingLastName: shippingAddress.lastname,
        shippingStreet: shippingAddress.street,
        shippingStreet2: shippingAddress.street2 || '',
        shippingZip: shippingAddress.zip,
        shippingCity: shippingAddress.city,
        shippingCountry: shippingAddress.country
      }
    }

    // If anonymous user
    if (!isNull(email)) {
      config.email = email
    }

    // If should register user upon checkout
    if (shouldRegister && !isNull(password)) {
      config.registerUserAccount = shouldRegister
      config.newUserAccountPassword = password

      if(!isEmpty(username)) {
        config.username = username
      }
    }

    // Add paypal credentials if PayPal set as payment method
    if (paymentType === 'PayPal' && !isNull(paypalAccount)) {
      config.paypalEmail = paypalAccount
    }

    // If company set
    if (!isEmpty(billingAddress.companyName) && !isInStorePickup) {
      config.billingCompany = billingAddress.companyName
    }
    // If company set
    if (!isEmpty(shippingAddress.companyName) && !isInStorePickup) {
      config.shippingCompany = shippingAddress.companyName
    }

    if (isEmpty(config.billingStreet2)) {
      delete config.billingStreet2
    }
    if (isEmpty(config.shippingStreet2)) {
      delete config.shippingStreet2
    }

    // If payment method is set through paypal
    if (paymentType === 'Bank Transfer' && !isNull(bankAccountNumber) && !isNull(bankSwift)) {
      config.bankAccountNumber = bankAccountNumber
      config.swift = bankSwift
    }

    return await runTask(createBuylistOrder({data: Order.mapProperties(config)}))
  },

  setBankAccount ({ commit }, { account }) {
    commit('SET_ACCOUNT', { type: 'bankAccount', value: account })
  },
  setBankAccountBic ({ commit }, { account }) {
    commit('SET_ACCOUNT', { type: 'bankAccountBic', value: account })
  },
  setPaypalAccount ({ commit }, { account }) {
    commit('SET_ACCOUNT', { type: 'paypalAccount', value: account })
  },

  setPaymentMethod ({ commit }, payload) {
    commit('PAYMENT_METHOD_SET', { payment: payload })
  },
  setShippingMethod ({ commit }, payload) {
    commit('SHIPPING_METHOD_SET', { transport: payload })
  },

  resetModule ({ commit }) {
    commit('RESET_MODULE')
  }
}

export const getters = {
  isInStorePickup: state => {
    return state.isInStorePickup
  },
  isInitialStorePickupAvailable: state => {
    return state.isInitialStorePickupAvailable
  },

  isAuthenticated: (state, getters, rootState, rootGetters) => {
    return rootGetters['authentication/isAuthenticated']
  },
  currencyShortcut: (state, getters, rootState, rootGetters) => {
    return rootGetters['general/getCurrency']
  },
  newsletterAgreement: (state) => {
    return state.agreements.newsletter
  },
  conditionsAgreement: (state) => {
    return state.agreements.conditions
  },
  paymentValidity: (state) => {
    return state.agreements.payment
  },

  paymentMethod: (state) => {
    if (state.paymentMethod) {
      return new PaymentMethod(state.paymentMethod)
    }
    return null
  },
  shippingMethod: (state) => {
    if (state.shippingMethod) {
      return new ShippingMethod(state.shippingMethod)
    }
    return null
  },
  isInPerson: (state) => {
    return state.shippingMethod?.id === 'IN_PERSON'
  },

  bankAccount: (state) => {
    return state.bankAccount || ''
  },
  bankAccountBic: (state) => {
    return state.bankAccountBic || ''
  },
  paypalAccount: (state) => {
    return state.paypalAccount
  },

  canAccessStep: (state, getters, rootState, rootGetters) => (index) => {
    const rules = [
      () => {
        return true
      },
      () => {
        return rootGetters['cartBuyup/buyupItemsCount'] > 0
      },
      () => {
        const hasItems = rootGetters['cartBuyup/buyupItemsCount'] > 0
        const isInStorePickup = getters.isInStorePickup
        const isAuthenticated = getters.isAuthenticated
        const shippingAddress = rootGetters['checkout/shippingAddress']
        const billingAddress = rootGetters['checkout/billingAddress']
        let isPaymentMethodValid = getters.paymentMethod !== null

        if (getters.paymentMethod && 'id' in getters.paymentMethod) {
          if (getters.paymentMethod.id === 'PayPal') {
            isPaymentMethodValid = !isEmpty(getters.paypalAccount)
          }

          if (getters.paymentMethod.id === 'Bank Transfer') {
            if (getters.currencyShortcut === 'CZK') {
              isPaymentMethodValid = !isEmpty(getters.bankAccount)
            } else {
              isPaymentMethodValid = !isEmpty(getters.bankAccount) && !isEmpty(getters.bankAccountBic)
            }
          }
        }

        let isBillingAddressValid = rootGetters['checkout/isBillingAddressValid']
        let isShippingAddressValid = shippingAddress.isValid && isEUCountry(shippingAddress.country)

        if (!getters.isAuthenticated) {
          const isIdenticalBillingAddress = rootGetters['checkout/isIdenticalBillingAddress']

          if (isIdenticalBillingAddress) {
            isShippingAddressValid = billingAddress.isValid && isEUCountry(billingAddress.country)
          }
        }

        if (isInStorePickup) {
          isShippingAddressValid = true
          isBillingAddressValid = (isAuthenticated || (billingAddress.isValidForStorePickup && !isEmpty(rootGetters['checkout/email'])))
        }

        return isPaymentMethodValid &&
          getters.shippingMethod !== null &&
          isShippingAddressValid &&
          isBillingAddressValid &&
          hasItems
      },
      () => {
        return state.agreements.conditions === true && state.agreements.payment === true
      }
    ]

    if (index >= rules.length) return false

    return rules[index]()
  }
}
