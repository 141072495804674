import { getMetaMockData } from '~/services/network/utils/helper.convenience.axios'
import LogService from '~/services/LogService'

export const state = () => ({
  configuration_global: {},
  configuration_singles: {},
})

export const mutations = {
  'SET_CONFIGURATION'(state, {type, configuration}) {
    state[type] = configuration
  }
}

export const actions = {
  async fetchConfiguration ({ commit }, {type}) {
    try {
      const result = await getMetaMockData({
          id: 'configurations',
          query: {type}
        })

      for (const configuration of result.data) {
        commit('SET_CONFIGURATION', {
          type: configuration.type,
          configuration,
        })
      }

    } catch (e) {
      LogService.error(e)
    }
  },
}

export const getters = {
  filtersForGame: state => id => {
    return state.configuration_singles?.filters?.find(item => item.game === id)
  },
}
